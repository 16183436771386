





































































































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Marketplace extends Vue {
  profiles: Array<{ name: string, image: string, bio: string }> = [
    { name: 'Abtin Janinejad', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'William Björk', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Anna Longoni', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Klaus Ladurner', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Francesca Boschetti', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Federica Grosso', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Barbara Cassoli', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Dagmar Jakob', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Barbara Ali', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Kevin England', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Rita Bellati', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Jurgen Heyman', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Michaela Tomei', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Alberta Cuoghi', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Adriana Caliri', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Barbara Stegmaier', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Marco Bertola', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Annemarie Visse', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Patrice Thornton', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Ultan Molloy', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },
    { name: 'Mark Light', image: 'okfkfo', bio: 'ksapkd aoskdpoas' },

  ];
}
